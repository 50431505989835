import { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../Helmet/Helmet";
import CommonSection from "../commonSection/CommonSection";
import File1 from "../../assets/pdfs/catalog.pdf";

import "./CatalogSections.css";

const CatalogSections = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Helmet title="کاتالوگ">
      <CommonSection title="کاتالوگ" />

      <section className="pt-0">
        <Container>
          <Row className="catalog-wrapper py-5">
            <Col lg="12">
              <div className="catalog d-flex flex-column gap-5">
                <div>
                  <h2>کاتالوگ محصولات</h2>
                  <p className="mt-3 mb-4">
                    برای دانلود کاتالوگ از لینک زیر اقدام فرمایید.
                  </p>
                </div>
              </div>
            </Col>

            <Col
              className="catalog-links d-flex justify-content-start mt-5"
              lg="12"
            >
              <div className="catalog-link">
                <a
                  className="d-flex gap-3"
                  href={File1}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span>
                    <i class="ri-download-line"></i>
                  </span>
                  <p>کاتالوگ</p>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CatalogSections;
