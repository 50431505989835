// Main Images Start
// ##JAZARFI
// import productImg101 from "../images/mainImages/r101.jpg";
// import productImg102 from "../images/mainImages/r102.jpg";
// import productImg103 from "../images/mainImages/r103.jpg";
// import productImg104 from "../images/mainImages/r104.jpg";
// import productImg105 from "../images/mainImages/r105.jpg";
// import productImg106 from "../images/mainImages/r106.jpg";
// import productImg107 from "../images/mainImages/r107.jpg";
// import productImg108 from "../images/mainImages/r108.jpg";

import productImg151 from "../images/mainImages/r151.jpg";
import productImg152 from "../images/mainImages/r152.jpg";
import productImg153 from "../images/mainImages/r153.jpg";
import productImg154 from "../images/mainImages/r154.jpg";
import productImg155 from "../images/mainImages/r155.jpg";
import productImg156 from "../images/mainImages/r156.jpg";
import productImg157 from "../images/mainImages/r157.jpg";


import productImg201 from "../images/mainImages/r201.jpg";
import productImg202 from "../images/mainImages/r202.jpg";
import productImg203 from "../images/mainImages/r203.jpg";
import productImg204 from "../images/mainImages/r204.jpg";
import productImg205 from "../images/mainImages/r205.jpg";
import productImg206 from "../images/mainImages/r206.jpg";
import productImg207 from "../images/mainImages/r207.jpg";
import productImg208 from "../images/mainImages/r208.jpg";
//
import productImg251 from "../images/mainImages/r251.jpg";
import productImg252 from "../images/mainImages/r252.jpg";
import productImg253 from "../images/mainImages/r253.jpg";
import productImg254 from "../images/mainImages/r254.jpg";
import productImg255 from "../images/mainImages/r255.jpg";
import productImg256 from "../images/mainImages/r256.jpg";
import productImg257 from "../images/mainImages/r257.jpg";
import productImg258 from "../images/mainImages/r258.jpg";
//
import productImg301 from "../images/mainImages/r301.jpg";
import productImg302 from "../images/mainImages/r302.jpg";
import productImg303 from "../images/mainImages/r303.jpg";
import productImg304 from "../images/mainImages/r304.jpg";
import productImg305 from "../images/mainImages/r305.jpg";
import productImg307 from "../images/mainImages/r307.jpg";
//
import productImg351 from "../images/mainImages/r351.jpg";
import productImg352 from "../images/mainImages/r352.jpg";
import productImg353 from "../images/mainImages/r353.jpg";
import productImg354 from "../images/mainImages/r354.jpg";
import productImg355 from "../images/mainImages/r355.jpg";
// import productImg357 from "../images/mainImages/r357.jpg";
import productImg358 from "../images/mainImages/r358.jpg";

// ##JALIFI
import productImg401 from "../images/mainImages/r401.jpg";
import productImg402 from "../images/mainImages/r402.jpg";
import productImg403 from "../images/mainImages/r403.jpg";
import productImg404 from "../images/mainImages/r404.jpg";
//
import productImg421 from "../images/mainImages/r421.jpg";
import productImg422 from "../images/mainImages/r422.jpg";
import productImg423 from "../images/mainImages/r423.jpg";
import productImg424 from "../images/mainImages/r424.jpg";
import productImg425 from "../images/mainImages/r425.jpg";
import productImg426 from "../images/mainImages/r426.jpg";

// ##JAPIYAZI
import productImg501 from "../images/mainImages/r501.jpg";
import productImg502 from "../images/mainImages/r502.jpg";
import productImg503 from "../images/mainImages/r503.jpg";
import productImg504 from "../images/mainImages/r504.jpg";
//
import productImg511 from "../images/mainImages/r511.jpg";
import productImg512 from "../images/mainImages/r512.jpg";
import productImg513 from "../images/mainImages/r513.jpg";
import productImg514 from "../images/mainImages/r514.jpg";
import productImg515 from "../images/mainImages/r515.jpg";
import productImg516 from "../images/mainImages/r516.jpg";
//
import productImg521 from "../images/mainImages/r521.jpg";
import productImg522 from "../images/mainImages/r522.jpg";
import productImg523 from "../images/mainImages/r523.jpg";
import productImg524 from "../images/mainImages/r524.jpg";
import productImg525 from "../images/mainImages/r525.jpg";
import productImg526 from "../images/mainImages/r526.jpg";
// import productImg527 from "../images/mainImages/r527.jpg";
//
import productImg531 from "../images/mainImages/r531.jpg";
import productImg532 from "../images/mainImages/r532.jpg";
import productImg533 from "../images/mainImages/r533.jpg";
import productImg534 from "../images/mainImages/r534.jpg";
import productImg535 from "../images/mainImages/r535.jpg";
import productImg536 from "../images/mainImages/r536.jpg";
// import productImg537 from "../images/mainImages/r57.jpg";
// import productImg538 from "../images/mainImages/r538.jpg";
import productImg543 from "../images/mainImages/r543.jpg";
import productImg544 from "../images/mainImages/r544.jpg";

// ##OTHER
import productImg601 from "../images/mainImages/r601.jpg";
import productImg602 from "../images/mainImages/r602.jpg";
// import productImg603 from "../images/mainImages/r603.jpg";
// import productImg604 from "../images/mainImages/r604.jpg";
//
import productImg801 from "../images/mainImages/r801.jpg";
// import productImg802 from "../images/mainImages/r802.jpg";
// import productImg803 from "../images/mainImages/r803.jpg";
import productImg805 from "../images/mainImages/r805.jpg";
import productImg806 from "../images/mainImages/r806.jpg";
import productImg841 from "../images/mainImages/r841.jpg";
import productImg842 from "../images/mainImages/r842.jpg";
//
import productImg851 from "../images/mainImages/r851.jpg";
import productImg852 from "../images/mainImages/r852.jpg";
import productImg853 from "../images/mainImages/r853.jpg";
//
import productImg902 from "../images/mainImages/r902.jpg";
import productImg903 from "../images/mainImages/r903.jpg";
import productImg904 from "../images/mainImages/r904.jpg";

// ##SABAD NAN
import productImg912 from "../images/mainImages/r912.jpg";
import productImg913 from "../images/mainImages/r913.jpg";
import productImg914 from "../images/mainImages/r914.jpg";

// SABAD BAFT
// import productImg1011 from "../images/mainImages/r1011.jpg";
// import productImg1012 from "../images/mainImages/r1012.jpg";
// import productImg1016 from "../images/mainImages/r1016.jpg";
// import productImg1017 from "../images/mainImages/r1017.jpg";
// import productImg1021 from "../images/mainImages/r1021.jpg";
// import productImg1022 from "../images/mainImages/r1022.jpg";
// import productImg1026 from "../images/mainImages/r1026.jpg";
// import productImg1027 from "../images/mainImages/r1027.jpg";

// Main Images End

// Cover Image white BG Start
// ##JAZARFI

import coverWhiteBgImg101 from "../images/white-bg/r101.jpg";
import coverWhiteBgImg102 from "../images/white-bg/r102.jpg";
import coverWhiteBgImg103 from "../images/white-bg/r103.jpg";
import coverWhiteBgImg104 from "../images/white-bg/r104.jpg";
import coverWhiteBgImg105 from "../images/white-bg/r105.jpg";
import coverWhiteBgImg106 from "../images/white-bg/r106.jpg";
import coverWhiteBgImg107 from "../images/white-bg/r107.jpg";
// import coverWhiteBgImg108 from "../images/white-bg/r108.jpg";
//
import coverWhiteBgImg151 from "../images/white-bg/r151.jpg";
import coverWhiteBgImg152 from "../images/white-bg/r152.jpg";
import coverWhiteBgImg153 from "../images/white-bg/r153.jpg";
import coverWhiteBgImg154 from "../images/white-bg/r154.jpg";
import coverWhiteBgImg155 from "../images/white-bg/r155.jpg";
import coverWhiteBgImg156 from "../images/white-bg/r156.jpg";
import coverWhiteBgImg157 from "../images/white-bg/r157.jpg";
// import coverWhiteBgImg158 from "../images/white-bg/r158.jpg";
//
import coverWhiteBgImg201 from "../images/white-bg/r201.jpg";
import coverWhiteBgImg202 from "../images/white-bg/r202.jpg";
import coverWhiteBgImg203 from "../images/white-bg/r203.jpg";
import coverWhiteBgImg204 from "../images/white-bg/r204.jpg";
import coverWhiteBgImg205 from "../images/white-bg/r205.jpg";
import coverWhiteBgImg206 from "../images/white-bg/r206.jpg";
import coverWhiteBgImg207 from "../images/white-bg/r207.jpg";
import coverWhiteBgImg208 from "../images/white-bg/r208.jpg";
//
import coverWhiteBgImg251 from "../images/white-bg/r251.jpg";
import coverWhiteBgImg252 from "../images/white-bg/r252.jpg";
import coverWhiteBgImg253 from "../images/white-bg/r253.jpg";
import coverWhiteBgImg254 from "../images/white-bg/r254.jpg";
import coverWhiteBgImg255 from "../images/white-bg/r255.jpg";
import coverWhiteBgImg256 from "../images/white-bg/r256.jpg";
import coverWhiteBgImg257 from "../images/white-bg/r257.jpg";
import coverWhiteBgImg258 from "../images/white-bg/r258.jpg";
//
import coverWhiteBgImg301 from "../images/white-bg/r301.jpg";
import coverWhiteBgImg302 from "../images/white-bg/r302.jpg";
import coverWhiteBgImg303 from "../images/white-bg/r303.jpg";
import coverWhiteBgImg304 from "../images/white-bg/r304.jpg";
import coverWhiteBgImg305 from "../images/white-bg/r305.jpg";
import coverWhiteBgImg307 from "../images/white-bg/r307.jpg";
//
import coverWhiteBgImg351 from "../images/white-bg/r351.jpg";
import coverWhiteBgImg352 from "../images/white-bg/r352.jpg";
import coverWhiteBgImg353 from "../images/white-bg/r353.jpg";
import coverWhiteBgImg354 from "../images/white-bg/r354.jpg";
import coverWhiteBgImg355 from "../images/white-bg/r355.jpg";
import coverWhiteBgImg357 from "../images/white-bg/r357.jpg";
import coverWhiteBgImg358 from "../images/white-bg/r358.jpg";

// ##JALIFI
import coverWhiteBgImg401 from "../images/white-bg/r401.jpg";
import coverWhiteBgImg402 from "../images/white-bg/r402.jpg";
import coverWhiteBgImg403 from "../images/white-bg/r403.jpg";
import coverWhiteBgImg404 from "../images/white-bg/r404.jpg";
//
import coverWhiteBgImg421 from "../images/white-bg/r421.jpg";
import coverWhiteBgImg422 from "../images/white-bg/r422.jpg";
import coverWhiteBgImg423 from "../images/white-bg/r423.jpg";
import coverWhiteBgImg424 from "../images/white-bg/r424.jpg";
import coverWhiteBgImg425 from "../images/white-bg/r425.jpg";
import coverWhiteBgImg426 from "../images/white-bg/r426.jpg";

// ##JAPIYAZI
import coverWhiteBgImg501 from "../images/white-bg/r501.jpg";
import coverWhiteBgImg502 from "../images/white-bg/r502.jpg";
import coverWhiteBgImg503 from "../images/white-bg/r503.jpg";
import coverWhiteBgImg504 from "../images/white-bg/r504.jpg";
//
import coverWhiteBgImg511 from "../images/white-bg/r511.jpg";
import coverWhiteBgImg512 from "../images/white-bg/r512.jpg";
import coverWhiteBgImg513 from "../images/white-bg/r513.jpg";
import coverWhiteBgImg514 from "../images/white-bg/r514.jpg";
import coverWhiteBgImg515 from "../images/white-bg/r515.jpg";
import coverWhiteBgImg516 from "../images/white-bg/r516.jpg";
//
import coverWhiteBgImg521 from "../images/white-bg/r521.jpg";
import coverWhiteBgImg522 from "../images/white-bg/r522.jpg";
import coverWhiteBgImg523 from "../images/white-bg/r523.jpg";
import coverWhiteBgImg524 from "../images/white-bg/r524.jpg";
import coverWhiteBgImg525 from "../images/white-bg/r525.jpg";
import coverWhiteBgImg526 from "../images/white-bg/r526.jpg";
import coverWhiteBgImg527 from "../images/white-bg/r527.jpg";
import coverWhiteBgImg528 from "../images/white-bg/r528.jpg";
//
import coverWhiteBgImg531 from "../images/white-bg/r531.jpg";
import coverWhiteBgImg532 from "../images/white-bg/r532.jpg";
import coverWhiteBgImg533 from "../images/white-bg/r533.jpg";
import coverWhiteBgImg534 from "../images/white-bg/r534.jpg";
import coverWhiteBgImg535 from "../images/white-bg/r535.jpg";
import coverWhiteBgImg536 from "../images/white-bg/r536.jpg";
import coverWhiteBgImg543 from "../images/white-bg/r543.jpg";
import coverWhiteBgImg544 from "../images/white-bg/r544.jpg";

// ##OTHER
import coverWhiteBgImg601 from "../images/white-bg/r601.jpg";
import coverWhiteBgImg602 from "../images/white-bg/r602.jpg";
import coverWhiteBgImg604 from "../images/white-bg/r604.jpg";
//
import coverWhiteBgImg801 from "../images/white-bg/r801.jpg";
// import coverWhiteBgImg803 from "../images/white-bg/r803.jpg";
import coverWhiteBgImg805 from "../images/white-bg/r805.jpg";
import coverWhiteBgImg806 from "../images/white-bg/r806.jpg";
import coverWhiteBgImg821 from "../images/white-bg/r821.jpg";
import coverWhiteBgImg825 from "../images/white-bg/r825.jpg";
import coverWhiteBgImg826 from "../images/white-bg/r826.jpg";
import coverWhiteBgImg841 from "../images/white-bg/r841.jpg";
import coverWhiteBgImg842 from "../images/white-bg/r842.jpg";
//
import coverWhiteBgImg851 from "../images/white-bg/r851.jpg";
import coverWhiteBgImg852 from "../images/white-bg/r852.jpg";
import coverWhiteBgImg853 from "../images/white-bg/r853.jpg";
//
import coverWhiteBgImg902 from "../images/white-bg/r902.jpg";
import coverWhiteBgImg903 from "../images/white-bg/r903.jpg";
import coverWhiteBgImg904 from "../images/white-bg/r904.jpg";

// ##SABAD NAN
import coverWhiteBgImg912 from "../images/white-bg/r912.jpg";
import coverWhiteBgImg913 from "../images/white-bg/r913.jpg";
import coverWhiteBgImg914 from "../images/white-bg/r914.jpg";

// SABAD BAFT
import coverWhiteBgImg1011 from "../images/white-bg/r1011.jpg";
import coverWhiteBgImg1012 from "../images/white-bg/r1012.jpg";
import coverWhiteBgImg1016 from "../images/white-bg/r1016.jpg";
import coverWhiteBgImg1017 from "../images/white-bg/r1017.jpg";
import coverWhiteBgImg1021 from "../images/white-bg/r1021.jpg";
import coverWhiteBgImg1022 from "../images/white-bg/r1022.jpg";
import coverWhiteBgImg1026 from "../images/white-bg/r1026.jpg";
import coverWhiteBgImg1027 from "../images/white-bg/r1027.jpg";



// Cover Image white BG End

const products = [
  //////////////////////////

  //  جا ظرفی روکش پلاستیک طوسی"
  {
    id: "01",
    productName: "جا ظرفی غزال ۲ طبقه کوچک",
    cover: "روکش پلاستیک",
    color: "طوسی",
    imgUrl: [coverWhiteBgImg101],
    category: "جا ظرفی",
    code: 101,
    productWeight: 1415,
    productDimensions: { length: 46.5, width: 26, height: 31 },
    boxWeight: 1850,
    boxDimensions: { length: 46.5, width: 27.5, height: 32 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "02",
    productName: "جا ظرفی غزال ۲ طبقه بزرگ",
    cover: "روکش پلاستیک",
    color: "طوسی",
    imgUrl: [coverWhiteBgImg102],
    category: "جا ظرفی",
    code: 102,
    productWeight: 1950,
    productDimensions: { length: 46.5, width: 37, height: 31.5 },
    boxWeight: 2500,
    boxDimensions: { length: 48.5, width: 38.5, height: 33 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "03",
    productName: "جا ظرفی غزال ۱ طبقه ",
    cover: "روکش پلاستیک",
    color: "طوسی",
    imgUrl: [coverWhiteBgImg103],
    category: "جا ظرفی",
    code: 103,
    productWeight: 1370,
    productDimensions: { length: 46.5, width: 37, height: 14 },
    boxWeight: 1690,
    boxDimensions: { length: 49.5, width: 38, height: 15.5 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "04",
    productName: "جا ظرفی نسترن ۲ طبقه ",
    cover: "روکش پلاستیک",
    color: "طوسی",
    imgUrl: [coverWhiteBgImg104],
    category: "جا ظرفی",
    code: 104,
    productWeight: 1750,
    productDimensions: { length: 51, width: 30, height: 33 },
    boxWeight: 2280,
    boxDimensions: { length: 45.5, width: 31.5, height: 36.5 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "05",
    productName: "جا ظرفی ویژه ۱ طبقه ",
    cover: "روکش پلاستیک",
    color: "طوسی",
    imgUrl: [coverWhiteBgImg105],
    category: "جا ظرفی",
    code: 105,
    productWeight: 1800,
    productDimensions: { length: 54, width: 41.5, height: 16 },
    boxWeight: 2250,
    boxDimensions: { length: 56.5, width: 42.5, height: 17 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "06",
    productName: "جا ظرفی ویژه ۲ طبقه ",
    cover: "روکش پلاستیک",
    color: "طوسی",
    imgUrl: [coverWhiteBgImg106],
    category: "جا ظرفی",
    code: 106,
    productWeight: 2405,
    productDimensions: { length: 52, width: 41.5, height: 32 },
    boxWeight: 3070,
    boxDimensions: { length: 52.5, width: 43, height: 33 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "07",
    productName: "جا ظرفی رویال ۱/۵ طبقه ",
    cover: "روکش پلاستیک",
    color: "طوسی",
    imgUrl: [coverWhiteBgImg107],
    category: "جا ظرفی",
    code: 107,
    productWeight: 2100,
    productDimensions: { length: 51, width: 40, height: 29.5 },
    boxWeight: 2700,
    boxDimensions: { length: 51, width: 42, height: 31 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  // {
  //   id: "08",
  //   productName: "جا ظرفی داخل سینکی",
  //   cover: "روکش پلاستیک",
  //   color: "طوسی",
  //   imgUrl: [coverWhiteBgImg108],
  //   category: "جا ظرفی",
  //   code: 108,
  //   productWeight: 590,
  //   productDimensions: { length: 37, width: 33, height: 12 },
  //   boxWeight: 7360,
  //   boxDimensions: { length: 39, width: 35, height: 42 },
  //   boxVolumeCalc: function () {
  //     return (
  //       (this.boxDimensions.length *
  //         this.boxDimensions.width *
  //         this.boxDimensions.height) /
  //       1000000
  //     );
  //   },
  //   packQuantity: 12,
  //   shortDesc: "جا ظرفی روکش پلاستیک",
  //   description: "در حال حاضر توضیحی وجود ندارد",
  // },
  //  جا ظرفی روکش پلاستیک مشکی"
  {
    id: "09",
    productName: "جا ظرفی غزال ۲ طبقه کوچک",
    cover: "روکش پلاستیک مشکی",
    color: "مشکی",
    imgUrl: [coverWhiteBgImg151, productImg151],
    category: "جا ظرفی",
    code: 151,
    productWeight: 1415,
    productDimensions: { length: 46.5, width: 26, height: 31 },
    boxWeight: 1850,
    boxDimensions: { length: 46.5, width: 27.5, height: 32 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "10",
    productName: "جا ظرفی غزال ۲ طبقه بزرگ",
    cover: "روکش پلاستیک",
    color: "مشکی",
    imgUrl: [coverWhiteBgImg152, productImg152],
    category: "جا ظرفی",
    code: 152,
    productWeight: 1950,
    productDimensions: { length: 46.5, width: 37, height: 31.5 },
    boxWeight: 2500,
    boxDimensions: { length: 48.5, width: 38.5, height: 33 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "11",
    productName: "جا ظرفی غزال ۱ طبقه ",
    cover: "روکش پلاستیک",
    color: "مشکی",
    imgUrl: [coverWhiteBgImg153, productImg153],
    category: "جا ظرفی",
    code: 153,
    productWeight: 1370,
    productDimensions: { length: 46.5, width: 37, height: 14 },
    boxWeight: 1690,
    boxDimensions: { length: 49.5, width: 38, height: 15.5 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "12",
    productName: "جا ظرفی نسترن ۲ طبقه ",
    cover: "روکش پلاستیک",
    color: "مشکی",
    imgUrl: [coverWhiteBgImg154, productImg154],

    category: "جا ظرفی",
    code: 154,
    productWeight: 1750,
    productDimensions: { length: 51, width: 30, height: 33 },
    boxWeight: 2280,
    boxDimensions: { length: 45.5, width: 31.5, height: 36.5 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "13",
    productName: "جا ظرفی ویژه ۱ طبقه ",
    cover: "روکش پلاستیک",
    color: "مشکی",
    imgUrl: [coverWhiteBgImg155, productImg155],

    category: "جا ظرفی",
    code: 155,
    productWeight: 1800,
    productDimensions: { length: 54, width: 41.5, height: 16 },
    boxWeight: 2250,
    boxDimensions: { length: 56.5, width: 42.5, height: 17 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "14",
    productName: "جا ظرفی ویژه ۲ طبقه ",
    cover: "روکش پلاستیک",
    color: "مشکی",
    imgUrl: [coverWhiteBgImg156, productImg156],

    category: "جا ظرفی",
    code: 156,
    productWeight: 2405,
    productDimensions: { length: 52, width: 41.5, height: 32 },
    boxWeight: 3070,
    boxDimensions: { length: 52.5, width: 43, height: 33 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "15",
    productName: "جا ظرفی رویال ۱/۵ طبقه ",
    cover: "روکش پلاستیک",
    color: "مشکی",
    imgUrl: [coverWhiteBgImg157, productImg157],

    category: "جا ظرفی",
    code: 157,
    productWeight: 2100,
    productDimensions: { length: 51, width: 40, height: 29.5 },
    boxWeight: 2700,
    boxDimensions: { length: 51, width: 42, height: 31 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  // {
  //   id: "16",
  //   productName: "جا ظرفی داخل سینکی",
  //   cover: "روکش پلاستیک",
  //   color: "مشکی",
  //   imgUrl: [coverWhiteBgImg158, productImg158],
  //   category: "جا ظرفی",
  //   code: 158,
  //   productWeight: 590,
  //   productDimensions: { length: 37, width: 33, height: 12 },
  //   boxWeight: 7360,
  //   boxDimensions: { length: 39, width: 35, height: 42 },
  //   boxVolumeCalc: function () {
  //     return (
  //       (this.boxDimensions.length *
  //         this.boxDimensions.width *
  //         this.boxDimensions.height) /
  //       1000000
  //     );
  //   },
  //   packQuantity: 12,
  //   shortDesc: "جا ظرفی روکش پلاستیک",
  //   description: "در حال حاضر توضیحی وجود ندارد",
  // },

  //  جا ظرفی روکش پلاستیک طوسی"
  {
    id: "17",
    productName: "جا ظرفی غزال ۲ طبقه کوچک",
    cover: "روکش پلاستیک",
    color: "سفید",
    imgUrl: [coverWhiteBgImg201, productImg201],
    category: "جا ظرفی",
    code: 201,
    productWeight: 1415,
    productDimensions: { length: 46.5, width: 26, height: 31 },
    boxWeight: 1850,
    boxDimensions: { length: 46.5, width: 27.5, height: 32 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "18",
    productName: "جا ظرفی غزال ۲ طبقه بزرگ",
    cover: "روکش پلاستیک",
    color: "سفید",
    imgUrl: [coverWhiteBgImg202, productImg202],
    category: "جا ظرفی",
    code: 202,
    productWeight: 1950,
    productDimensions: { length: 46.5, width: 37, height: 31.5 },
    boxWeight: 2500,
    boxDimensions: { length: 48.5, width: 38.5, height: 33 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "19",
    productName: "جا ظرفی غزال ۱ طبقه ",
    cover: "روکش پلاستیک",
    color: "سفید",
    imgUrl: [coverWhiteBgImg203, productImg203],
    category: "جا ظرفی",
    code: 203,
    productWeight: 1370,
    productDimensions: { length: 46.5, width: 37, height: 14 },
    boxWeight: 1690,
    boxDimensions: { length: 49.5, width: 38, height: 15.5 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "20",
    productName: "جا ظرفی نسترن ۲ طبقه ",
    cover: "روکش پلاستیک",
    color: "سفید",
    imgUrl: [coverWhiteBgImg204, productImg204],
    category: "جا ظرفی",
    code: 204,
    productWeight: 1750,
    productDimensions: { length: 51, width: 30, height: 33 },
    boxWeight: 2280,
    boxDimensions: { length: 45.5, width: 31.5, height: 36.5 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "21",
    productName: "جا ظرفی ویژه ۱ طبقه ",
    cover: "روکش پلاستیک",
    color: "سفید",
    imgUrl: [coverWhiteBgImg205, productImg205],
    category: "جا ظرفی",
    code: 205,
    productWeight: 1800,
    productDimensions: { length: 54, width: 41.5, height: 16 },
    boxWeight: 2250,
    boxDimensions: { length: 56.5, width: 42.5, height: 17 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "22",
    productName: "جا ظرفی ویژه ۲ طبقه ",
    cover: "روکش پلاستیک",
    color: "سفید",
    imgUrl: [coverWhiteBgImg206, productImg206],
    category: "جا ظرفی",
    code: 206,
    productWeight: 2405,
    productDimensions: { length: 52, width: 41.5, height: 32 },
    boxWeight: 3070,
    boxDimensions: { length: 52.5, width: 43, height: 33 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "23",
    productName: "جا ظرفی رویال ۱/۵ طبقه ",
    cover: "روکش پلاستیک",
    color: "سفید",
    imgUrl: [coverWhiteBgImg207, productImg207],
    category: "جا ظرفی",
    code: 207,
    productWeight: 2100,
    productDimensions: { length: 51, width: 40, height: 29.5 },
    boxWeight: 2700,
    boxDimensions: { length: 51, width: 42, height: 31 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "24",
    productName: "جا ظرفی داخل سینکی",
    cover: "روکش پلاستیک",
    color: "سفید",
    imgUrl: [coverWhiteBgImg208, productImg208],
    category: "جا ظرفی",
    code: 208,
    productWeight: 590,
    productDimensions: { length: 37, width: 33, height: 12 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 12,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },

  // جا ظرفی  استیل (نیکل کروم)

  {
    id: "25",
    productName: "جا ظرفی غزال ۲ طبقه کوچک",
    cover: "روکش نیکل کروم",
    color: "سفید",
    imgUrl: [coverWhiteBgImg251, productImg251],
    category: "جا ظرفی",
    code: 251,
    productWeight: 1510,
    productDimensions: { length: 46.5, width: 26, height: 31 },
    boxWeight: 1850,
    boxDimensions: { length: 46.5, width: 27.5, height: 32 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "26",
    productName: "جا ظرفی غزال ۲ طبقه بزرگ",
    cover: "روکش نیکل کروم",
    color: "سفید",
    imgUrl: [coverWhiteBgImg252, productImg252],
    category: "جا ظرفی",
    code: 252,
    productWeight: 2030,
    productDimensions: { length: 46.5, width: 37, height: 31.5 },
    boxWeight: 2500,
    boxDimensions: { length: 48.5, width: 38.5, height: 33 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "27",
    productName: "جا ظرفی غزال ۱ طبقه ",
    cover: "روکش نیکل کروم",
    color: "سفید",
    imgUrl: [coverWhiteBgImg253, productImg253],
    category: "جا ظرفی",
    code: 253,
    productWeight: 1400,
    productDimensions: { length: 46.5, width: 37, height: 14 },
    boxWeight: 1690,
    boxDimensions: { length: 49.5, width: 38, height: 15.5 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "28",
    productName: "جا ظرفی نسترن ۲ طبقه ",
    cover: "روکش نیکل کروم",
    color: "سفید",
    imgUrl: [coverWhiteBgImg254, productImg254],
    category: "جا ظرفی",
    code: 254,
    productWeight: 1750,
    productDimensions: { length: 51, width: 30, height: 33 },
    boxWeight: 2280,
    boxDimensions: { length: 45.5, width: 31.5, height: 36.5 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "29",
    productName: "جا ظرفی ویژه ۱ طبقه ",
    cover: "روکش نیکل کروم",
    color: "سفید",
    imgUrl: [coverWhiteBgImg255, productImg255],
    category: "جا ظرفی",
    code: 255,
    productWeight: 1960,
    productDimensions: { length: 54, width: 41.5, height: 16 },
    boxWeight: 2250,
    boxDimensions: { length: 56.5, width: 42.5, height: 17 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "30",
    productName: "جا ظرفی ویژه ۲ طبقه ",
    cover: "روکش نیکل کروم",
    color: "سفید",
    imgUrl: [coverWhiteBgImg256, productImg256],
    category: "جا ظرفی",
    code: 256,
    productWeight: 2425,
    productDimensions: { length: 52, width: 41.5, height: 32 },
    boxWeight: 3070,
    boxDimensions: { length: 52.5, width: 43, height: 33 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "31",
    productName: "جا ظرفی رویال ۱/۵ طبقه ",
    cover: "روکش نیکل کروم",
    color: "سفید",
    imgUrl: [coverWhiteBgImg257, productImg257],
    category: "جا ظرفی",
    code: 257,
    productWeight: 2200,
    productDimensions: { length: 51, width: 40, height: 29.5 },
    boxWeight: 2700,
    boxDimensions: { length: 51, width: 42, height: 31 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "32",
    productName: "جا ظرفی داخل سینکی",
    cover: "روکش نیکل کروم",
    color: "سفید",
    imgUrl: [coverWhiteBgImg258, productImg258],
    category: "جا ظرفی",
    code: 258,
    productWeight: 635,
    productDimensions: { length: 37, width: 33, height: 12 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 12,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },

  // جا ظرفی طلایی (سینی کرم وانیلی)

  {
    id: "33",
    productName: "جا ظرفی غزال ۲ طبقه کوچک",
    cover: "روکش طلایی - سینی کرم وانیلی",
    color: "سفید",
    imgUrl: [coverWhiteBgImg301, productImg301],
    category: "جا ظرفی",
    code: 301,
    productWeight: 1510,
    productDimensions: { length: 46.5, width: 26, height: 31 },
    boxWeight: 1850,
    boxDimensions: { length: 46.5, width: 27.5, height: 32 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "34",
    productName: "جا ظرفی غزال ۲ طبقه بزرگ",
    cover: "روکش طلایی - سینی کرم وانیلی",
    color: "سفید",
    imgUrl: [coverWhiteBgImg302, productImg302],
    category: "جا ظرفی",
    code: 302,
    productWeight: 2030,
    productDimensions: { length: 46.5, width: 37, height: 31.5 },
    boxWeight: 2500,
    boxDimensions: { length: 48.5, width: 38.5, height: 33 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "35",
    productName: "جا ظرفی غزال ۱ طبقه ",
    cover: "روکش طلایی - سینی کرم وانیلی",
    color: "سفید",
    imgUrl: [coverWhiteBgImg303, productImg303],
    category: "جا ظرفی",
    code: 303,
    productWeight: 1400,
    productDimensions: { length: 46.5, width: 37, height: 14 },
    boxWeight: 1690,
    boxDimensions: { length: 49.5, width: 38, height: 15.5 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "36",
    productName: "جا ظرفی نسترن ۲ طبقه ",
    cover: "روکش طلایی - سینی کرم وانیلی",
    color: "سفید",
    imgUrl: [coverWhiteBgImg304, productImg304],
    category: "جا ظرفی",
    code: 304,
    productWeight: 1750,
    productDimensions: { length: 51, width: 30, height: 33 },
    boxWeight: 2280,
    boxDimensions: { length: 45.5, width: 31.5, height: 36.5 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "37",
    productName: "جا ظرفی ویژه ۱ طبقه ",
    cover: "روکش طلایی - سینی کرم وانیلی",
    color: "سفید",
    imgUrl: [coverWhiteBgImg305, productImg305],
    category: "جا ظرفی",
    code: 305,
    productWeight: 1960,
    productDimensions: { length: 54, width: 41.5, height: 16 },
    boxWeight: 2250,
    boxDimensions: { length: 56.5, width: 42.5, height: 17 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "38",
    productName: "جا ظرفی رویال ۱/۵ طبقه ",
    cover: "روکش طلایی - سینی کرم وانیلی",
    color: "سفید",
    imgUrl: [coverWhiteBgImg307, productImg307],
    category: "جا ظرفی",
    code: 307,
    productWeight: 2200,
    productDimensions: { length: 51, width: 40, height: 29.5 },
    boxWeight: 2700,
    boxDimensions: { length: 51, width: 42, height: 31 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },

  // "جا ظرفی طلایی (سینی مشکی)"

  {
    id: "39",
    productName: "جا ظرفی غزال ۲ طبقه کوچک",
    cover: "روکش طلایی - سینی مشکی",
    color: "سفید",
    imgUrl: [coverWhiteBgImg351, productImg351],
    category: "جا ظرفی",
    code: 351,
    productWeight: 1510,
    productDimensions: { length: 46.5, width: 26, height: 31 },
    boxWeight: 1850,
    boxDimensions: { length: 46.5, width: 27.5, height: 32 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "40",
    productName: "جا ظرفی غزال ۲ طبقه بزرگ",
    cover: "روکش طلایی - سینی مشکی",
    color: "سفید",
    imgUrl: [coverWhiteBgImg352, productImg352],
    category: "جا ظرفی",
    code: 352,
    productWeight: 2030,
    productDimensions: { length: 46.5, width: 37, height: 31.5 },
    boxWeight: 2500,
    boxDimensions: { length: 48.5, width: 38.5, height: 33 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "41",
    productName: "جا ظرفی غزال ۱ طبقه ",
    cover: "روکش طلایی - سینی مشکی",
    color: "سفید",
    imgUrl: [coverWhiteBgImg353, productImg353],
    category: "جا ظرفی",
    code: 353,
    productWeight: 1400,
    productDimensions: { length: 46.5, width: 37, height: 14 },
    boxWeight: 1690,
    boxDimensions: { length: 49.5, width: 38, height: 15.5 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "42",
    productName: "جا ظرفی نسترن ۲ طبقه ",
    cover: "روکش طلایی - سینی مشکی",
    color: "سفید",
    imgUrl: [coverWhiteBgImg354, productImg354],
    category: "جا ظرفی",
    code: 354,
    productWeight: 1750,
    productDimensions: { length: 51, width: 30, height: 33 },
    boxWeight: 2280,
    boxDimensions: { length: 45.5, width: 31.5, height: 36.5 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "43",
    productName: "جا ظرفی ویژه ۱ طبقه ",
    cover: "روکش طلایی - سینی مشکی",
    color: "سفید",
    imgUrl: [coverWhiteBgImg355, productImg355],
    category: "جا ظرفی",
    code: 355,
    productWeight: 1960,
    productDimensions: { length: 54, width: 41.5, height: 16 },
    boxWeight: 2250,
    boxDimensions: { length: 56.5, width: 42.5, height: 17 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "44",
    productName: "جا ظرفی رویال ۱/۵ طبقه ",
    cover: "روکش طلایی - سینی مشکی",
    color: "سفید",
    imgUrl: [coverWhiteBgImg357],
    category: "جا ظرفی",
    code: 357,
    productWeight: 2200,
    productDimensions: { length: 51, width: 40, height: 29.5 },
    boxWeight: 2700,
    boxDimensions: { length: 51, width: 42, height: 31 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "45",
    productName: "جا ظرفی داخل سینکی",
    cover: "روکش طلایی - سینی مشکی",
    color: "سفید",
    imgUrl: [coverWhiteBgImg358, productImg358],
    category: "جا ظرفی",
    code: 358,
    productWeight: 635,
    productDimensions: { length: 37, width: 33, height: 12 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 12,
    shortDesc: "جا ظرفی روکش پلاستیک",
    description: "در حال حاضر توضیحی وجود ندارد",
  },

  /////////////////////////
  // جا لیفی حمام

  {
    id: "46",
    productName: "جا لیفی نایس ۲ طبقه",
    cover: "روکش طلایی",
    imgUrl: [coverWhiteBgImg401, productImg401],
    category: "جا لیفی حمام",
    code: 401,
    productWeight: 970,
    productDimensions: { length: 28, width: 14, height: 42 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا لیفی حمام",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "47",
    productName: "جا لیفی نایس ۳ طبقه",
    cover: "روکش طلایی",
    imgUrl: [coverWhiteBgImg402, productImg402],
    category: "جا لیفی حمام",
    code: 402,
    productWeight: 1365,
    productDimensions: { length: 28, width: 14, height: 58.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا لیفی حمام",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "48",
    productName: "جا لیفی نایس ۳ طبقه",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg403, productImg403],
    category: "جا لیفی حمام",
    code: 403,
    productWeight: 905,
    productDimensions: { length: 28, width: 14, height: 42 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 6,
    shortDesc: "جا لیفی حمام",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "49",
    productName: "جا لیفی نایس ۳ طبقه",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg404, productImg404],
    category: "جا لیفی حمام",
    code: 404,
    productWeight: 1220,
    productDimensions: { length: 28, width: 14, height: 58.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 6,
    shortDesc: "جا لیفی حمام",
    description: "در حال حاضر توضیحی وجود ندارد",
  },

  // سه گوش حمام

  {
    id: "50",
    productName: "سه گوش حمام نسیم ۲ طبقه",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg421, productImg421],
    category: "جا لیفی حمام",
    code: 421,
    productWeight: 720,
    productDimensions: { length: 24, width: 24, height: 35 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 8,
    shortDesc: "سه گوش حمام",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "51",
    productName: "سه گوش حمام نسیم ۳ طبقه",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg422, productImg422],
    category: "جا لیفی حمام",
    code: 422,
    productWeight: 1120,
    productDimensions: { length: 24, width: 24, height: 52 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 8,
    shortDesc: "سه گوش حمام",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "52",
    productName: "سه گوش حمام نایس ۲ طبقه",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg423, productImg423],
    category: "جا لیفی حمام",
    code: 423,
    productWeight: 875,
    productDimensions: { length: 22, width: 22, height: 46 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 8,
    shortDesc: "سه گوش حمام",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "53",
    productName: "سه گوش حمام نایس ۳ طبقه",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg424, productImg424],
    category: "جا لیفی حمام",
    code: 424,
    productWeight: 1215,
    productDimensions: { length: 22, width: 22, height: 65 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 8,
    shortDesc: "سه گوش حمام",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "54",
    productName: "سه گوش حمام ارکیده ۲ طبقه",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg425, productImg425],
    category: "جا لیفی حمام",
    code: 425,
    productWeight: 770,
    productDimensions: { length: 24, width: 24, height: 40 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 8,
    shortDesc: "سه گوش حمام",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "55",
    productName: "سه گوش حمام ارکیده ۳ طبقه",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg426, productImg426],
    category: "جا لیفی حمام",
    code: 426,
    productWeight: 1125,
    productDimensions: { length: 24, width: 24, height: 60 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 8,
    shortDesc: "سه گوش حمام",
    description: "در حال حاضر توضیحی وجود ندارد",
  },

  // جا پیازی

  {
    id: "56",
    productName: "جا پیازی آرام ۲ طبقه",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg501, productImg501],
    category: "جا پیازی",
    code: 501,
    productWeight: 1105,
    productDimensions: { length: 30.5, width: 30.5, height: 43 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 4,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "57",
    productName: "جا پیازی آرام ۳ طبقه",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg502, productImg502],
    category: "جا پیازی",
    code: 502,
    productWeight: 1640,
    productDimensions: { length: 30.5, width: 30.5, height: 60 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 4,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "58",
    productName: "جا پیازی اطلس ۲ طبقه",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg503, productImg503],
    category: "جا پیازی",
    code: 503,
    productWeight: 835,
    productDimensions: { length: 28.5, width: 28, height: 43 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 5,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "59",
    productName: "جا پیازی اطلس ۳ طبقه",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg504, productImg504],
    category: "جا پیازی",
    code: 504,
    productWeight: 1175,
    productDimensions: { length: 28.5, width: 28.5, height: 60 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 5,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },

  // جاپیازی ۲ طبقه کشویی روکش نیکل کروم
  {
    id: "60",
    productName: "جا پیازی ۲ طبقه کشویی",
    cover: "روکش نیکل کروم",
    imgUrl: [coverWhiteBgImg511, productImg511],
    category: "جا پیازی",
    code: 511,
    productWeight: 3270,
    productDimensions: { length: 32, width: 32, height: 54 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "61",
    productName: "جا پیازی ۳ طبقه کشویی",
    cover: "روکش نیکل کروم",
    imgUrl: [coverWhiteBgImg512, productImg512],
    category: "جا پیازی",
    code: 512,
    productWeight: 4050,
    productDimensions: { length: 32, width: 32, height: 73 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "62",
    productName: "جا پیازی ۲ طبقه کشویی",
    cover: "رنگ الکترواستاتیک (سفید)",
    imgUrl: [coverWhiteBgImg513, productImg513],
    category: "جا پیازی",
    code: 513,
    productWeight: 3270,
    productDimensions: { length: 32, width: 32, height: 54.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "63",
    productName: "جا پیازی ۳ طبقه کشویی",
    cover: "رنگ الکترواستاتیک (سفید)",
    imgUrl: [coverWhiteBgImg514, productImg514],
    category: "جا پیازی",
    code: 514,
    productWeight: 4050,
    productDimensions: { length: 32, width: 32, height: 73 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "64",
    productName: "جا پیازی ۲ طبقه کشویی",
    cover: "مشکی طلایی",
    imgUrl: [coverWhiteBgImg515, productImg515],
    category: "جا پیازی",
    code: 515,
    productWeight: 3270,
    productDimensions: { length: 32, width: 32, height: 54.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "65",
    productName: "جا پیازی ۳ طبقه کشویی",
    cover: "مشکی طلایی",
    imgUrl: [coverWhiteBgImg516, productImg516],
    category: "جا پیازی",
    code: 516,
    productWeight: 4050,
    productDimensions: { length: 32, width: 32, height: 73 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },

  // -------------------------------------

  {
    id: "66",
    productName: "جا پیازی مانا ۲ طبقه (مربع)",
    cover: "کروم طلایی",
    imgUrl: [coverWhiteBgImg521, productImg521],
    category: "جا پیازی",
    code: 521,
    productWeight: 2150,
    productDimensions: { length: 26.5, width: 25.5, height: 45 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "67",
    productName: "جا پیازی مانا ۳ طبقه (مربع)",
    cover: "کروم طلایی",
    imgUrl: [coverWhiteBgImg522, productImg522],
    category: "جا پیازی",
    code: 522,
    productWeight: 3080,
    productDimensions: { length: 26.5, width: 25.5, height: 68.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "68",
    productName: "جا پیازی مانا ۲ طبقه (مربع)",
    cover: "رنگ مشکی",
    imgUrl: [coverWhiteBgImg523, productImg523],
    category: "جا پیازی",
    code: 523,
    productWeight: 2150,
    productDimensions: { length: 26.5, width: 25.5, height: 45 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "69",
    productName: "جا پیازی مانا ۳ طبقه (مربع)",
    cover: "رنگ مشکی",
    imgUrl: [coverWhiteBgImg524, productImg524],
    category: "جا پیازی",
    code: 524,
    productWeight: 3080,
    productDimensions: { length: 26.5, width: 25.5, height: 68.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },

  ///////////////////////////
  ///////////////////////////
  ///////////////////////////
  ///////////////////////////

  {
    id: "70",
    productName: "جا پیازی مانا ۲ طبقه (مربع)",
    cover: "رنگ طلایی",
    imgUrl: [coverWhiteBgImg525, productImg525],
    category: "جا پیازی",
    code: 525,
    productWeight: 2150,
    productDimensions: { length: 26.5, width: 25.5, height: 45 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "71",
    productName: "جا پیازی مانا ۳ طبقه (مربع)",
    cover: "رنگ طلایی",
    imgUrl: [coverWhiteBgImg526, productImg526],
    category: "جا پیازی",
    code: 526,
    productWeight: 3080,
    productDimensions: { length: 26.5, width: 25.5, height: 68.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "72",
    productName: "جا پیازی مانا ۲ طبقه (مستطیل)",
    cover: "رنگ طلایی",
    imgUrl: [coverWhiteBgImg531, productImg531],
    category: "جا پیازی",
    code: 531,
    productWeight: 1910,
    productDimensions: { length: 17.5, width: 30, height: 45.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "73",
    productName: "جا پیازی مانا ۲ طبقه (مستطیل)",
    cover: "رنگ طلایی",
    imgUrl: [coverWhiteBgImg532, productImg532],
    category: "جا پیازی",
    code: 532,
    productWeight: 2725,
    productDimensions: { length: 17.5, width: 30, height: 68 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "74",
    productName: "جا پیازی مانا ۲ طبقه (مستطیل)",
    cover: "رنگ مشکی",
    imgUrl: [coverWhiteBgImg533, productImg533],
    category: "جا پیازی",
    code: 533,
    productWeight: 1910,
    productDimensions: { length: 17.5, width: 30, height: 45.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "75",
    productName: "جا پیازی مانا ۳ طبقه (مستطیل)",
    cover: "رنگ مشکی",
    imgUrl: [coverWhiteBgImg534, productImg534],
    category: "جا پیازی",
    code: 534,
    productWeight: 2725,
    productDimensions: { length: 17.5, width: 30, height: 68 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "76",
    productName: "جا پیازی مانا ۲ طبقه (مستطیل)",
    cover: "رنگ طلایی",
    imgUrl: [coverWhiteBgImg535, productImg535],
    category: "جا پیازی",
    code: 535,
    productWeight: 1910,
    productDimensions: { length: 17.5, width: 30, height: 45.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "77",
    productName: "جا پیازی مانا ۳ طبقه (مستطیل)",
    cover: "روکش طلایی",
    imgUrl: [coverWhiteBgImg536, productImg536],
    category: "جا پیازی",
    code: 536,
    productWeight: 2725,
    productDimensions: { length: 17.5, width: 30, height: 68 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "78",
    productName: "جا پیازی ارغوان ۲ طبقه (مربع)",
    cover: "رنگ مشکی",
    imgUrl: [coverWhiteBgImg543, productImg543],
    category: "جا پیازی",
    code: 543,
    productWeight: 2725,
    productDimensions: { length: 17.5, width: 30, height: 68 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "79",
    productName: "جا پیازی ارغوان ۳ طبقه (مربع)",
    cover: "رنگ مشکی",
    imgUrl: [coverWhiteBgImg544, productImg544],
    category: "جا پیازی",
    code: 544,
    productWeight: 2725,
    productDimensions: { length: 17.5, width: 30, height: 68 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 1,
    shortDesc: "جا پیازی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },

  /////////////////////////////
  /////////////////////////////
  /////////////////////////////
  /////////////////////////////

  // بند رخت

  {
    id: "80",
    productName: "بند رخت لوله ای مدل نایس",
    cover: "رنگ الکترواستاتیک (سیلور)",
    imgUrl: [coverWhiteBgImg601, productImg601],
    category: "بند رخت",
    code: 601,
    productWeight: 4500,
    productDimensions: { length: 17.5, width: 30, height: 68 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 4,
    shortDesc: "بند رخت لوله ای",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "81",
    productName: "بند رخت لوله ای مدل نایس",
    cover: "رنگ الکترواستاتیک (سفید)",
    imgUrl: [coverWhiteBgImg602, productImg602],
    category: "بند رخت",
    code: 602,
    productWeight: 4500,
    productDimensions: { length: 17.5, width: 30, height: 68 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 4,
    shortDesc: "بند رخت لوله ای",
    description: "در حال حاضر توضیحی وجود ندارد",
  },

  {
    id: "82",
    productName: "بند رخت لوله ای مدل نایس",
    cover: "رنگ الکترواستاتیک (مشکی)",
    imgUrl: [coverWhiteBgImg604],
    category: "بند رخت",
    code: 604,
    productWeight: 4500,
    productDimensions: { length: 17.5, width: 30, height: 68 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 4,
    shortDesc: "بند رخت لوله ای",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  // سایر

  {
    id: "83",
    productName: "پشت دری سر ساچمه ای",
    cover: "روکش نیکل کروم",
    imgUrl: [coverWhiteBgImg801, productImg801],
    category: "سایر",
    code: 801,
    productWeight: 465,
    productDimensions: { length: 50, width: "-", height: 20 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "آویز",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "84",
    productName: "پشت دری سر ساچمه ای",
    cover: "رنگ مشکی",
    imgUrl: [coverWhiteBgImg821],
    category: "سایر",
    code: 821,
    productWeight: 465,
    productDimensions: { length: 50, width: "-", height: 20 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "آویز",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "85",
    productName: "دیوارکوب ۵ شاخه",
    cover: "روکش نیکل کروم",
    imgUrl: [coverWhiteBgImg805, productImg805],
    category: "سایر",
    code: 805,
    productWeight: 315,
    productDimensions: { length: 40, width: "-", height: 14 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "آویز",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "86",
    productName: "دیوارکوب ۶ شاخه",
    cover: "روکش نیکل کروم",
    imgUrl: [coverWhiteBgImg806, productImg806],
    category: "سایر",
    code: 806,
    productWeight: 380,
    productDimensions: { length: 50, width: "-", height: 14 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "آویز",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "87",
    productName: "جا لیوانی آویز",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg841, productImg841],
    category: "سایر",
    code: 841,
    productWeight: 410,
    productDimensions: { length: 24, width: 12, height: 36 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "آویز",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "88",
    productName: "جا لیوانی آویز",
    cover: "روکش نیکل کروم",
    imgUrl: [coverWhiteBgImg842, productImg842],
    category: "سایر",
    code: 842,
    productWeight: 485,
    productDimensions: { length: 24, width: 12, height: 36 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "آویز",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "89",
    productName: "جا نایلونی",
    cover: "روکش پلاستیک",
    imgUrl: [coverWhiteBgImg851, productImg851],
    category: "سایر",
    code: 851,
    productWeight: 670,
    productDimensions: { length: 20, width: 10, height: 33 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 12,
    shortDesc: "آویز",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "90",
    productName: "جا نایلونی",
    cover: "روکش نیکل کروم",
    imgUrl: [coverWhiteBgImg852, productImg852],
    category: "سایر",
    code: 852,
    productWeight: 700,
    productDimensions: { length: 20, width: 10, height: 33 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 12,
    shortDesc: "آویز",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "91",
    productName: "جا نایلونی",
    cover: "طلایی",
    imgUrl: [coverWhiteBgImg853, productImg853],
    category: "سایر",
    code: 853,
    productWeight: 700,
    productDimensions: { length: 20, width: 10, height: 33 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 12,
    shortDesc: "آویز",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "72",
    productName: "جا دستمال رول رومیزی",
    cover: "روکش نیکل کروم",
    imgUrl: [coverWhiteBgImg902, productImg902],
    category: "سایر",
    code: 902,
    productWeight: 500,
    productDimensions: { length: 17.5, width: 15.5, height: 33.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "رومیزی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "73",
    productName: "جا دستمال رول رومیزی",
    cover: "طلایی",
    imgUrl: [coverWhiteBgImg903, productImg903],
    category: "سایر",
    code: 903,
    productWeight: 500,
    productDimensions: { length: 17.5, width: 15.5, height: 33.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "رومیزی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "74",
    productName: "جا دستمال رول رومیزی",
    cover: "مشکی",
    imgUrl: [coverWhiteBgImg904, productImg904],
    category: "سایر",
    code: 904,
    productWeight: 500,
    productDimensions: { length: 17.5, width: 15.5, height: 33.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "رومیزی",
    description: "در حال حاضر توضیحی وجود ندارد",
  },

  // سبد نان

  {
    id: "75",
    productName: "سبد نان مربع",
    cover: "روکش نیکل کروم",
    imgUrl: [coverWhiteBgImg912, productImg912],
    category: "سبد نان",
    code: 912,
    productWeight: 590,
    productDimensions: { length: 20, width: 20.5, height: 10.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "سبد نان",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "76",
    productName: "سبد نان مربع",
    cover: "طلایی",
    imgUrl: [coverWhiteBgImg913, productImg913],
    category: "سبد نان",
    code: 913,
    productWeight: 590,
    productDimensions: { length: 20, width: 20.5, height: 10.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "سبد نان",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "77",
    productName: "سبد نان مربع",
    cover: "مشکی",
    imgUrl: [coverWhiteBgImg914, productImg914],
    category: "سبد نان",
    code: 914,
    productWeight: 590,
    productDimensions: { length: 20, width: 20.5, height: 10.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "سبد نان",
    description: "در حال حاضر توضیحی وجود ندارد",
  },

  // صندوق بافت
  {
    id: "78",
    productName: "صندوق 3 تکه بیضی",
    cover: "رنگ طوسی",
    imgUrl: [coverWhiteBgImg1011],
    category: "سبد بافت",
    code: 1011,
    productWeight: 3700,
    productDimensions: { length: 32, width: 21.5, height: 46.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "صندوق بافت",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "79",
    productName: "صندوق 3 تکه بیضی",
    cover: "رنگ قهوه ای",
    imgUrl: [coverWhiteBgImg1012],
    category: "سبد بافت",
    code: 1012,
    productWeight: 3700,
    productDimensions: { length: 32, width: 21.5, height: 46.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "صندوق بافت",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "80",
    productName: "صندوق 3 تکه مستطیل",
    cover: "رنگ طوسی",
    imgUrl: [coverWhiteBgImg1016],
    category: "سبد بافت",
    code: 1016,
    productWeight: 3700,
    productDimensions: { length: 32, width: 21.5, height: 46.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "صندوق بافت",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "81",
    productName: "صندوق 3 تکه مستطیل",
    cover: "رنگ قهوه ای",
    imgUrl: [coverWhiteBgImg1017],
    category: "سبد بافت",
    code: 1017,
    productWeight: 3700,
    productDimensions: { length: 32, width: 21.5, height: 46.5 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "صندوق بافت",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "82",
    productName: "سبد رخت کوچک",
    cover: "رنگ طوسی",
    imgUrl: [coverWhiteBgImg1021],
    category: "سبد بافت",
    code: 1021,
    productWeight: 3000,
    productDimensions: { length: 41.5, width: 32, height: 52 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "سبد رخت",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "83",
    productName: "سبد رخت کوچک",
    cover: "رنگ قهوه ای",
    imgUrl: [coverWhiteBgImg1022],
    category: "سبد بافت",
    code: 1022,
    productWeight: 3000,
    productDimensions: { length: 41.5, width: 32, height: 52 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "سبد رخت",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "84",
    productName: "سبد رخت بزرگ",
    cover: "رنگ طوسی",
    imgUrl: [coverWhiteBgImg1026],
    category: "سبد بافت",
    code: 1026,
    productWeight: 4000,
    productDimensions: { length: 46.5, width: 38, height: 62 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "سبد رخت",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
  {
    id: "85",
    productName: "سبد رخت بزرگ",
    cover: "رنگ قهوه ای",
    imgUrl: [coverWhiteBgImg1027],
    category: "سبد بافت",
    code: 1027,
    productWeight: 4000,
    productDimensions: { length: 46, width: 38, height: 62 },
    boxWeight: 7360,
    boxDimensions: { length: 39, width: 35, height: 42 },
    boxVolumeCalc: function () {
      return (
        (this.boxDimensions.length *
          this.boxDimensions.width *
          this.boxDimensions.height) /
        1000000
      );
    },
    packQuantity: 24,
    shortDesc: "سبد رخت",
    description: "در حال حاضر توضیحی وجود ندارد",
  },
];

export default products;
