import { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import Lightbox from "yet-another-react-lightbox";
import products from "../../assets/data/products";
import Helmet from "../Helmet/Helmet";
import CommonSection from "../commonSection/CommonSection";
import ProductsList from "../products-list/ProductsList";
import "./ProductDetailsSection.css";
import "yet-another-react-lightbox/styles.css";

const ProductDetails = () => {
  const { productCode } = useParams();
  const product = products.find((item) => item.code === Number(productCode));
  const {
    productName,
    cover,
    imgUrl,
    category,
    code,
    productWeight,
    productDimensions,
    description,
    shortDesc,
    // cartonCBM,
    // numberInTheBox,
  } = product;
  const [open, setOpen] = useState(false);
  const [main, setMain] = useState(imgUrl.length > 1 ? imgUrl[1] : imgUrl[0]);
console.log(imgUrl[0])
  const relatedProducts = products.filter(
    (item) => item.code !== Number(productCode) && item.category === category
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    setMain(imgUrl.length > 1 ? imgUrl[1] : imgUrl[0]);
  }, [product || productCode]);

  return (
    <Helmet title={productName}>
      <CommonSection title={productName} />

      <section className="pt-5">
        <Container>
          <Row className="product__details-wrapper d-flex align-items-start ">
            <Col lg="6">
              <div className="LightboxContainer">
                <img
                  src={main}
                  alt=""
                  className="main"
                  onClick={() => setOpen(true)}
                />
                <div className="gallery">
                  {imgUrl.map((image, index) => {
                    return (
                      <motion.img
                        src={image}
                        alt=""
                        key={index}
                        className={`${image === main ? "active" : null}`}
                        whileHover={{ scale: 0.9 }}
                        onClick={() => setMain(imgUrl[index])}
                      />
                    );
                  })}
                </div>
              </div>
              <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={imgUrl.length > 1 ? [{ src: imgUrl[1] }, { src: imgUrl[0] }] : [{ src: imgUrl[0] }] }
              />
            </Col>
            <Col lg="6">
              <div className="product__details d-flex flex-column align-items-start mt-5 ">
                <h2>{productName}</h2>

                <div className="tab__wrapper-custom d-flex align-items-center gap-5">
                  <div className="d-flex align-items-center gap-5">
                    <span>دسته بندی: {category.toUpperCase()}</span>
                    <span>گروه: {cover}</span>
                  </div>
                  <span>کد محصول: {code}</span>
                </div>
                <div className="tab__wrapper mt-5">
                  <h6 className="carton">مشخصات محصول</h6>
                  <div className="tab__wrapper-row d-flex flex-row  align-items-start gap-5 mt-3">
                    <div className="d-flex align-items-start gap-5">
                      <span>طول : {productDimensions.length}cm</span>
                      <span>عرض : {productDimensions.width}cm</span>
                    </div>
                    <div className="d-flex align-items-center gap-5">
                      <span>ارتفاع : {productDimensions.height}cm</span>
                      <span>وزن : {productWeight}gr</span>
                    </div>
                  </div>
                </div>
                <div className="tab__wrapper mt-5 ">
                  <h6 className="desc">توضیحات محصول</h6>
                  <div className="tab__content my-3">
                    <p>{description}</p>
                  </div>
                </div>

                {/* <div className="tab__wrapper mt-4">
                  <h6 className="carton">مشخصات کارتن</h6>
                  <div className="tab__wrapper-custom d-flex md-flex-column align-items-start gap-5 mt-3">
                    <div className="d-flex align-items-start gap-5">
                      <span>تعداد در کارتن: {numberInTheBox}</span>
                      <span>ابعاد کارتن: {cartonDimensions}</span>
                    </div>
                    <div className="d-flex align-items-center gap-5">
                      <span>وزن کارتن : {cartonWeight}</span>
                      <span>CBM کارتن: {cartonCBM}</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mt-2">
              <h2 className="related__title">محصولات مشابه</h2>
            </Col>

            <ProductsList data={relatedProducts.slice(0, 4)} />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default ProductDetails;
