import { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../Helmet/Helmet";
import CommonSection from "../commonSection/CommonSection";
import PricesList from "../../assets/pdfs/pricesList.pdf";
import SabadBaftPricesList from "../../assets/pdfs/sabadBaftPriceList.pdf";

import "./PricesListSections.css";

const PricesListSections = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Helmet title="لیست قیمت">
      <CommonSection title="لیست قیمت" />

      <section className="pt-0 prices__list-container">
        <Container>
          <Row className="prices__list-wrapper py-5">
            <Col lg="12">
              <div className="prices__list d-flex flex-column gap-5">
                <div>
                  <h2>لیست قیمت محصولات</h2>
                  <p className="mt-3 mb-4">
                    برای دانلود لیست قیمت از لینک زیر اقدام فرمایید.
                  </p>
                </div>
              </div>
            </Col>

            <Col
              className="prices__list-links d-flex justify-content-start mt-5"
              lg="12"
            >
              <div className="prices__list-link">
                <a
                  className="d-flex gap-3"
                  href={PricesList}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span>
                    <i class="ri-download-line"></i>
                  </span>
                  <p>لیست قیمت عکس دار (موقت)</p>
                </a>
              </div>
            </Col>
          </Row>

          <Row className="prices__list-wrapper py-5">
            <Col lg="12">
              <div className="prices__list d-flex flex-column gap-5">
                <div>
                  <h2>لیست قیمت سبد بافت</h2>
                  <p className="mt-3 mb-4">
                    برای دانلود لیست قیمت سبد بافت از لینک زیر اقدام فرمایید.
                  </p>
                </div>
              </div>
            </Col>

            <Col
              className="prices__list-links d-flex justify-content-start mt-5"
              lg="12"
            >
              <div className="prices__list-link">
                <a
                  className="d-flex gap-3"
                  href={SabadBaftPricesList}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span>
                    <i class="ri-download-line"></i>
                  </span>
                  <p>لیست قیمت سبد بافت (موقت)</p>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default PricesListSections;
