

import HomeSections from "../components/home-sections/HomeSections"

const Home = () => {
  return (
    <div>
      <HomeSections />
    </div>
  )
}

export default Home