

import ProductsSection from "../components/products-sections/productsSections"


const Products = () => {
  return (
    <ProductsSection/>
  )
}

export default Products