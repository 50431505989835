import { useState, useEffect } from "react";
import CommonSection from "../commonSection/CommonSection";
import Helmet from "../Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";

import "./productsSection.css";

import products from "../../assets/data/products";
import ProductList from "../products-list/ProductsList";

function Products() {
  const [productsData, setProductsData] = useState(products);

  const handleFilter = (e) => {
    const filterValue = e.target.value;

    if (filterValue === "جا ظرفی") {
      const filteredProducts = products.filter(
        (item) => item.category === "جا ظرفی"
      );
      setProductsData(filteredProducts);
    }

    if (filterValue === "جا لیفی حمام") {
      const filteredProducts = products.filter(
        (item) => item.category === "جا لیفی حمام"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "جا پیازی") {
      const filteredProducts = products.filter(
        (item) => item.category === "جا پیازی"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "بند رخت") {
      const filteredProducts = products.filter(
        (item) => item.category === "بند رخت"
      );
      setProductsData(filteredProducts);
    }

    if (filterValue === "سایر") {
      const filteredProducts = products.filter(
        (item) => item.category === "سایر"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "سبد نان") {
      const filteredProducts = products.filter(
        (item) => item.category === "سبد نان"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "سبد بافت") {
      const filteredProducts = products.filter(
        (item) => item.category === "سبد بافت"
      );

      setProductsData(filteredProducts);
    }

    if (filterValue === "default") {
      const filteredProducts = products;

      setProductsData(filteredProducts);
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;

    const searchedProducts = products.filter((item) => {
      return item.productName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setProductsData(searchedProducts);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Helmet title="محصولات">
      <CommonSection title="محصولات" />

      <section className="">
        <Container>
          <Row className="d-flex align-items-center justify-content-center">
            <Col lg="2" md="6 mt-2">
              <div className="filter__widget">
                <select onChange={handleFilter}>
                  <option value="default">دسته بندی</option>
                  <option value="جا ظرفی">جاظرفی</option>
                  <option value="جا لیفی حمام">جالیفی</option>
                  <option value="جا پیازی">جاپیازی</option>
                  <option value="بند رخت">بندرخت</option>
                  <option value="سبد نان">سبد نان</option>
                  <option value="سبد بافت">سبد بافت</option>
                  <option value="سایر">سایر</option>
                </select>
              </div>
            </Col>

            <Col lg="7" md="12 mt-2">
              <div className="search__box">
                <input
                  type="text"
                  placeholder="به دنبال چه چیزی می‌گردید؟"
                  onChange={handleSearch}
                />
                <span>
                  <i class="ri-search-line"></i>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-0">
        <Container>
          <Row>
            {productsData.length === 0 ? (
              <h1 className="text-center fs-4">محصولی پیدا نشد!!!</h1>
            ) : (
              <ProductList data={productsData} />
            )}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
}

export default Products;
