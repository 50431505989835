import { useEffect } from "react";
import Helmet from "../Helmet/Helmet";
import "./homeSections.css";
import { Container, Row, Col } from "reactstrap";

import AUtoplaySlider from "../Autoplay-slider/AutoplaySlider";
import ProductSLider from "../Product-slider/ProductSlider";

import banner1 from "../../assets/images/jaLeafyBanner.jpg";
import banner2 from "../../assets/images/jaNaniBanner.jpg";
import banner3 from "../../assets/images/jaPiazyBanner.jpg";

const HomeSections = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Helmet title="صفحه اصلی">
      {/* <section className="home__banner home__banner-1 mt-2">
        <Container fluid>
          <Row>
            <Col lg="12">
              <AUtoplaySlider />
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* <section className="home__banner home__banner-1 mt-1">
        <Container fluid>
          <Row className="text-center mb-1">
            <h4>محصولات محبوب</h4>
          </Row>
          <Row>
            <Col lg="12">
              <ProductSLider />
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* <section className="home__banner home__banner-1 mt-1">
        <Container fluid>
          <Row className="text-center mb-1">
            <h4>محصولات برتر</h4>
          </Row>
          <Row>
            <Col lg="12">
              <ProductSLider />
            </Col>
          </Row>
        </Container>
      </section> */}

      <section className="home__banner home__banner-1 mt-2">
        <Container>
          <Row>
            <Col lg="12">
              <div className="banner__content d-flex flex-column align-items-center justify-content-center">
                <img src={banner1} alt="heroImage" />
                <h2>.Complete your Beautiful Life with us</h2>
                <h2>زندگی زیبایتان را با ما کامل کنید.</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="home__banner home__banner-2 mt-2">
        <Container>
          <Row>
            <Col lg="12">
              <div className="banner__content d-flex flex-column align-items-center justify-content-center">
                <img src={banner2} alt="heroImage" />
                <h2>تفاوت در جزئیات است.</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="home__banner home__banner-3 mt-2">
        <Container>
          <Row>
            <Col lg="12">
              <div className="banner__content d-flex flex-column align-items-center justify-content-center">
                <img src={banner3} alt="heroImage" />
                <h2>کیفیت را با ما تجربه کنید.</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default HomeSections;
