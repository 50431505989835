import { useState, useRef, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { Container, Row } from "reactstrap";
import "./header.css";

import homeIcon from "../../assets/icons/home-icon.svg";
import productsIcon from "../../assets/icons/products-icon.svg";
import priceListIcon from "../../assets/icons/priceList-icon.svg";
import contactUsIcon from "../../assets/icons/contactUs-icon.svg";
import galleryIcon from "../../assets/icons/gallery-icon.svg";

import logo from "../../assets/images/logo.png";

import { slide as Menu } from "react-burger-menu";

const nav__links = [
  {
    path: "/",
    display: "صفحه اصلی",
    icon: homeIcon,
  },
  {
    path: "products",
    display: "محصولات",
    icon: productsIcon,
  },
  {
    path: "prices-list",
    display: "لیست قیمت",
    icon: priceListIcon,
  },
  {
    path: "catalog",
    display: "کاتالوگ",
    icon: galleryIcon,
  },
  // {
  //   path: "about",
  //   display: "درباره ما",
  //   icon: contactUsIcon,
  // },
];

const Header = () => {
  const [isMenuOpen, handleMenu] = useState(false);
  const headerRef = useRef(null);

  const stickyHeaderFunc = () => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add("sticky__header");
      } else {
        headerRef.current.classList.add("sticky__header");
      }
    });
  };

  useEffect(() => {
    stickyHeaderFunc();

    return () => window.removeEventListener("scroll", stickyHeaderFunc);
  });

  const handleCloseMenu = () => {
    handleMenu(false);
  };
  const handleStateChange = (state) => {
    handleMenu(state.isOpen);
  };

  return (
    <header className="header" ref={headerRef}>
      <Container>
        <Row>
          <div className="nav__wrapper">
            <div className="mobile__menu">
              <Menu
                width={230}
                right
                pageWrapId={"page-wrap"}
                isOpen={isMenuOpen}
                onStateChange={handleStateChange}
              >
                {nav__links.map((item, index) => (
                  <span className="nav__item menu-item" key={index}>
                    <NavLink
                      to={item.path}
                      className={(navClass) =>
                        navClass.isActive ? "nav__active" : ""
                      }
                      onClick={() => handleCloseMenu()}
                    >
                      <img src={item.icon} alt="صفحه اصلی" className="icon" />
                      <span>{item.display}</span>
                    </NavLink>
                  </span>
                ))}
              </Menu>
            </div>

            <div className="navigation">
              <ul className="menu">
                {nav__links.map((item, index) => (
                  <li className="nav__item" key={index}>
                    <NavLink
                      to={item.path}
                      className={(navClass) =>
                        navClass.isActive ? "nav__active" : ""
                      }
                    >
                      <img src={item.icon} alt="صفحه اصلی" className="icon" />
                      <span>{item.display}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className="logo">
              <Link to="/" className="logo">
                <img src={logo} alt="Logo" />
                <div>
                  <h1>پیشتاز کاوه</h1>
                </div>
              </Link>
            </div>
          </div>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
