import { Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Home from "./routes/Home";
import Products from "./routes/Products";
import ProductDetails from "./routes/ProductDetails";
// import About from "./routes/About";
import PricesList from "./routes/PricesList";
import Catalog from "./routes/Catalog";
import NotFoundPage from "./routes/NotFoundPage";
import Footer from "./components/footer/Footer";

import "./App.css";
import "./assets/css/fonts.css";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path="products" element={<Products />} />
        <Route path="product/:productCode" element={<ProductDetails />} />
        {/* <Route path="about" element={<About />} /> */}
        <Route path="prices-list" element={<PricesList />} />
        <Route path="catalog" element={<Catalog />} />

        <Route path="*" element={<NotFoundPage />} />
        
      </Routes>
      <Footer />
    </>
  );
}

export default App;
