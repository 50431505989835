import { motion } from "framer-motion";
import "./productCard.css";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProductCard = ({ item }) => {
  return (
    <Col xxl="3" xl="4" lg="4" md="6" className="my-3">
      <Link className="productCard__link" to={`/product/${item.code}`}>
        <motion.div
          whileHover={{ scale: 0.9 }}
          className="product__item d-flex flex-column align-items-center justify-content-center"
        >
          <div className="product__img">
            <LazyLoadImage
              effect="blur"
              alt={item.productName}
              src={item.imgUrl[0]}
              // height="250px"
              // width="400px"
            />
          </div>
          <div className="w-100 pt-4 pb-3 product__info d-flex flex-column align-items-start justify-content-center">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <h3 className="product__name ">{item.productName}</h3>
              <span>کد: {item.code}</span>
            </div>
            <span className="pt-3">{item.cover}</span>
          </div>
          <div className="product__card-bottom d-flex align-items-center justify-content-between p-2">
            {/* <span className="price">${item.price}</span> */}
          </div>
        </motion.div>
      </Link>
    </Col>
  );
};

export default ProductCard;
