import "./footer.css";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-between">
          {/* <Col lg="4" md="6" className="mb-4">
            <div className="logo">
              <div>
                <h1 className="text-white">پیشتاز کاوه</h1>
              </div>
            </div>
            <p className="footer__text mt-4">
              شرکت پیشتاز کاوه با بیش از ۱۵ سال سابقه در تولید لوازم خانه و
              آشپزخانه فلزی اعم از انواع آبچکان و استند های آشپزخانه و سرویس
              آشپزخانه و سرویس حمام ،با روکش های پلاستیکی و آبکاری نیکل کروم و
              فورتیک و رنگ الکترواستاتیک .
            </p>
          </Col> */}

          <Col lg="auto" md="3" className="mb-4 text-lg-center">
            <div className="footer__quick-links">
              <h4 className="quick__links-title">دسترسی سریع</h4>
              <ListGroup className="mb-3 text-end footer__contact">
                <ListGroupItem className="ps-0 border-0">
                  <Link to="/products">محصولات</Link>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0">
                  <Link to="/prices-list">لیست قیمت</Link>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0">
                  <Link to="/about">درباره ما</Link>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>

          <Col lg="auto" md="6" className="mb-4 text-lg-center ">
            <div className="footer__quick-links">
              <h4 className="quick__links-title">تماس</h4>
              <ListGroup className="footer__contact text-end">
                <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-2">
                  <span>
                    <i class="ri-map-pin-line"></i>
                  </span>
                  <a
                    href="https://goo.gl/maps/hZTAo9xVhureSJ4G9"
                    target="_blank"
                  >
                    <p>
                      پاکدشت شریف آباد شهرک صنعتی شنزار میدان پنجم گلستان هفتم
                      غربی پلاک ۹۷ و ۹۸
                    </p>
                  </a>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-2">
                  <span>
                    <i class="ri-phone-line"></i>
                  </span>
                  <p>
                    <a href="tel:+982178631">تلفن ثابت: 78631-021</a>
                  </p>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-2">
                  <span>
                    <i class="ri-phone-line"></i>
                  </span>
                  <p>
                    <a href="tel:+989120078631">موبایل فروش: 0078631-0912</a>
                  </p>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-2">
                  <span>
                    <i class="ri-phone-line"></i>
                  </span>
                  <p>
                    <a href="tel:+989372222102">موبایل حسابداری: 2222102-0937</a>
                  </p>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-2">
                  <span>
                    <i class="ri-mail-line"></i>
                  </span>
                  <p>pishtaz.kaveh@gmail.com</p>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>
          <Col lg="auto" md="3" className="mb-4 text-lg-center">
            <div className="footer__quick-links">
              <h4 className="quick__links-title">رسانه های پیشتاز کاوه</h4>
              <ListGroup className="footer__contact text-end">
                <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-2">
                  <span>
                    <i class="ri-instagram-line"></i>
                  </span>
                  <p>
                    <a href="https://instagram.com/pishtazkaveh?igshid=YmMyMTA2M2Y=">
                      اینستاگرام
                    </a>
                  </p>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-2">
                  <span>
                    <i class="ri-telegram-line"></i>
                  </span>
                  <p>
                    <a href="https://t.me/pishtazkaveh">کانال تلگرام</a>
                  </p>
                </ListGroupItem>
                <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-2">
                  <span>
                    <i class="ri-telegram-line"></i>
                  </span>
                  <p>
                    <a href="https://t.me//+989337523600">تلگرام</a>
                  </p>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0 d-flex align-items-center gap-2">
                  <span>
                    <i class="ri-whatsapp-line"></i>
                  </span>
                  <p>
                    <a href="https://wa.me/+989337523600">واتساپ</a>
                  </p>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>

          <Col lg="12">
            <p className="footer__copyright">
              تمامی حقوق مادی و معنوی این وبسایت متعلق به پیشتاز کاوه میباشد و
              محفوظ است.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
